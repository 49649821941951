import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { NgIf, NgTemplateOutlet } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { ColorMode, KeysOfType } from "src/standard/ts/types";
import { appTitle } from "src/config";
import invariant from "tiny-invariant";
import { StandardNavSideButtonCollapserComponent } from "../standard-nav-side/standard-nav-side-button-collapser/standard-nav-side-button-collapser.component";
import { StandardNavSideComponent } from "../standard-nav-side/standard-nav-side.component";
import { StandardNavTopComponent } from "../standard-nav-top/standard-nav-top.component";
import { StandardSkipNavContentComponent } from "../standard-skip-nav/standard-skip-nav-content.component";
import { StandardSkipNavLinkComponent } from "../standard-skip-nav/standard-skip-nav-link.component";

export const XS = 400 as const;
export const SM = 576 as const;
export const MD = 768 as const;
export const LG = 992 as const;
export const XL = 1200 as const;
export type WidthCategory =
  | typeof XS
  | typeof SM
  | typeof MD
  | typeof LG
  | typeof XL;

export function getWidthCategory(width: number): WidthCategory {
  if (width < XS) {
    return XS;
  }

  if (width < MD) {
    return SM;
  }

  if (width < LG) {
    return MD;
  }

  if (width < XL) {
    return LG;
  }

  return XL;
}

type TemplateSlot<T extends string> = Extract<
  KeysOfType<StandardNavShellComponent, TemplateRef<unknown> | null>,
  `${T}${string}`
>;
export type NavTemplatesMap = Record<
  Extract<Omit<TemplateSlot<"nav">, "navTopTemplate">, string>,
  TemplateRef<unknown> | null
>;

@Component({
  standalone: true,
  selector: "standard-nav-shell",
  templateUrl: "./standard-nav-shell.component.html",
  styles: [
    `
      @import "../../standard/styles/helpers/variables";

      /* Make ResizeObserver work properly */
      :host {
        display: block;
        height: 100%;

        .side-overlay {
          z-index: $zindex-offcanvas-backdrop;
        }
      }
    `,
  ],
  animations: [
    trigger("navSideCollapserContent", [
      state(
        "void",
        style({
          marginLeft: "calc(-1 * var(--bs-w-sidenav))",
          visibility: "hidden",
        }),
      ),
      state(
        "collapsed",
        style({
          marginLeft: "calc(-1 * var(--bs-w-sidenav))",
          visibility: "hidden",
        }),
      ),
      state(
        "expanded",
        style({
          marginLeft: "0",
          visibility: "visible",
        }),
      ),
      transition("expanded <=> collapsed", [
        animate(".2s cubic-bezier(0.86, 0, 0.07, 1)"),
      ]),
      transition("void => *", animate(0)),
    ]),
    trigger("navSideOverlayBg", [
      state(
        "void",
        style({
          opacity: "0",
          visibility: "hidden",
        }),
      ),
      state(
        "collapsed",
        style({
          opacity: "0",
          visibility: "hidden",
        }),
      ),
      state(
        "expanded",
        style({
          opacity: "1",
          visibility: "visible",
        }),
      ),
      transition("expanded <=> collapsed", [
        animate(".2s cubic-bezier(0.86, 0, 0.07, 1)"),
      ]),
      transition("void => *", animate(0)),
    ]),
    trigger("navSideOverlayContent", [
      state(
        "void",
        style({
          transform: "translateX(calc(-1 * var(--bs-w-sidenav)))",
          width: "var(--bs-w-sidenav)",
          visibility: "hidden",
        }),
      ),
      state(
        "collapsed",
        style({
          transform: "translateX(calc(-1 * var(--bs-w-sidenav)))",
          width: "var(--bs-w-sidenav)",
          visibility: "hidden",
        }),
      ),
      state(
        "expanded",
        style({
          transform: "translateX(0)",
          width: "var(--bs-w-sidenav)",
          visibility: "visible",
        }),
      ),
      state(
        "expandedFull",
        style({
          transform: "translateX(0)",
          width: "100%",
          visibility: "visible",
        }),
      ),
      transition("expanded <=> expandedFull", [
        animate(".2s cubic-bezier(0.86, 0, 0.07, 1)"),
      ]),
      transition("expandedFull <=> collapsed", [
        animate(".2s cubic-bezier(0.86, 0, 0.07, 1)"),
      ]),
      transition("expanded <=> collapsed", [
        animate(".2s cubic-bezier(0.86, 0, 0.07, 1)"),
      ]),
      transition("void => *", animate(0)),
    ]),
  ],
  imports: [
    NgIf,
    NgTemplateOutlet,

    StandardNavTopComponent,
    StandardNavSideComponent,
    StandardNavSideButtonCollapserComponent,

    StandardSkipNavLinkComponent,
    StandardSkipNavContentComponent,
  ],
})
export class StandardNavShellComponent implements OnInit, OnDestroy {
  appTitle = appTitle;
  XS = XS;
  SM = SM;
  MD = MD;
  LG = LG;
  XL = XL;

  constructor(
    private host: ElementRef<HTMLElement>,
    private zone: NgZone,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    // Auto-dismiss side nav when navigating
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.navSideOverlayed && this.navSideExpanded) {
          this.toggleNavSide(false);
        }

        // automatically scroll to top
        this.contentScroller?.nativeElement.scrollTo(0, 0);
      }
    });
  }

  @Input() topColorMode: ColorMode | undefined;
  @Input() topStyleClass: string | undefined;
  @Input() sideColorMode: ColorMode | undefined;

  @Input() topStartToSideStartMaxSize: number = MD;
  @Input() topEndToSideEndMaxSize: number = SM;
  @Input() sideOverlayFullMaxSize: number = MD;
  @Input() sideOverlayExpandedFullMaxSize: number = XS;
  @Input() defaultSideCollapsedMaxSize: number = MD;
  @Input() autoHideSideNavCutoffMaxSize: number = MD;
  @Input() autoShowSideNavCutoffMinSize: number = XL;
  @Input() autoShowSideNavCutoffMaxSize: number = XL * 99;

  @ContentChild("navTop", { static: false, descendants: false })
  navTopTemplate: TemplateRef<unknown> | null = null;

  @ContentChild("navTopLogo", { static: false, descendants: false })
  navTopLogoTemplate: TemplateRef<unknown> | null = null;

  @ContentChild("navTopMiddleLogo", { static: false, descendants: false })
  navTopMiddleLogoTemplate: TemplateRef<unknown> | null = null;

  @ContentChild("navTopStartActions", { static: false, descendants: false })
  navTopStartActionsTemplate: TemplateRef<unknown> | null = null;

  @ContentChild("navTopEndActions", { static: false, descendants: false })
  navTopEndActionsTemplate: TemplateRef<unknown> | null = null;

  hasNavSide: boolean | undefined;

  @ContentChild("navSideHeader", { static: false, descendants: false })
  navSideHeaderTemplate: TemplateRef<unknown> | null = null;

  @ContentChild("navSideStartActions", { static: false, descendants: false })
  navSideStartActionsTemplate: TemplateRef<unknown> | null = null;

  @ContentChild("navSideEndActions", { static: false, descendants: false })
  navSideEndActionsTemplate: TemplateRef<unknown> | null = null;

  @ContentChild("navSideEndSmallishOnlyActions", {
    static: false,
    descendants: false,
  })
  navSideEndSmallishOnlyActionsTemplate: TemplateRef<unknown> | null = null;

  @ContentChild("navSideStartLargishOnlyActions", {
    static: false,
    descendants: false,
  })
  navSideStartLargishOnlyActionsTemplate: TemplateRef<unknown> | null = null;

  @ContentChild("navBottom", { static: false, descendants: false })
  navBottomTemplate: TemplateRef<unknown> | null = null;

  @ViewChild("contentScroller") contentScroller:
    | ElementRef<HTMLElement>
    | undefined;

  protected transitionState: "idle" | "loading" = "loading";

  protected navSideExpanded: boolean | undefined;
  protected navSideOverlayed: boolean | undefined;
  private observer: ResizeObserver | undefined;
  protected widthCategory: WidthCategory | undefined;

  ngOnInit() {
    this.observer = new ResizeObserver((entries) => {
      this.observer!.unobserve(this.host.nativeElement);

      this.zone.run(() => {
        const newWidthCategory = getWidthCategory(
          entries[0]!.contentRect.width,
        );
        this.resetShellExpansions(newWidthCategory);

        this.transitionState = "idle";
        setTimeout(() => {
          this.observer!.observe(this.host.nativeElement);
        });
      });
    });

    this.observer.observe(this.host.nativeElement);
  }

  ngOnDestroy() {
    this.observer?.unobserve(this.host.nativeElement);
  }

  private resetShellExpansions(newCategory: WidthCategory) {
    const oldCategory = this.widthCategory;
    if (this.widthCategory === undefined || newCategory !== oldCategory) {
      this.navSideOverlayed = newCategory <= this.sideOverlayFullMaxSize;

      // Default to hidden or shown
      if (oldCategory === undefined) {
        if (newCategory <= this.defaultSideCollapsedMaxSize) {
          this.toggleNavSide(false);
        } else {
          this.toggleNavSide(true);
        }
      } else {
        // When changing to small, auto-hide
        if (
          oldCategory > this.autoHideSideNavCutoffMaxSize &&
          newCategory <= this.autoHideSideNavCutoffMaxSize
        ) {
          this.toggleNavSide(false);
        }
        // When changing to large, auto-show
        else if (
          oldCategory < this.autoShowSideNavCutoffMinSize &&
          newCategory >= this.autoShowSideNavCutoffMinSize &&
          newCategory <= this.autoShowSideNavCutoffMaxSize
        ) {
          this.toggleNavSide(true);
        }
      }

      this.widthCategory = newCategory;
    }

    this.hasNavSide =
      this.widthCategory <= this.topStartToSideStartMaxSize ||
      this.widthCategory <= this.topEndToSideEndMaxSize ||
      !!this.navSideStartActionsTemplate ||
      !!this.navSideEndActionsTemplate ||
      (this.widthCategory > MD &&
        !!this.navSideStartLargishOnlyActionsTemplate);
  }

  public toggleNavSide(newExpanded?: boolean) {
    if (newExpanded === undefined) {
      this.navSideExpanded = !this.navSideExpanded;
    } else if (this.navSideExpanded !== newExpanded) {
      this.navSideExpanded = newExpanded;
    }

    // Show/hiden scrollbar
    if (this.navSideExpanded && this.navSideOverlayed) {
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;

      document.body.style.overflowY = "hidden";
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      document.body.style.overflowY = "auto";
      document.body.style.paddingRight = "0px";
    }
  }

  public registerNavTemplates(maps: NavTemplatesMap) {
    for (const [key, val] of Object.entries(maps)) {
      // eslint-disable-next-line
      (this as any)[key] = val ?? undefined;
    }

    invariant(this.widthCategory);
    this.resetShellExpansions(this.widthCategory);

    // Refresh
    this.changeDetectorRef.detectChanges();
  }
}

import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { EntityError, HttpResponse } from "breeze-client";
import { StandardLogService } from "src/standard/logging/logging";
import { toast } from "src/standard/ts/toast";
import { ServerSidePublicConfigDataService } from "./server-side-config-data/server-side-public-config-data.service";

interface InnerError {
  entityErrors: EntityError[];
  httpResponse?: HttpResponse;
  message: string;
  stack?: string;
  status?: number;
}

export interface BreezeQueuedSaveFailedError {
  innerError: InnerError;
  message: string;
  failedSaveMemo: unknown;
  nextSaveMemo: unknown;
}

export function parseQueuedSaveFailedError(
  error: BreezeQueuedSaveFailedError,
): string {
  return error.innerError.entityErrors.map((e) => e.errorMessage).join("\n");
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private zone: NgZone,
    private logger: StandardLogService,
    private serverSidePublicConfig: ServerSidePublicConfigDataService,
  ) {}

  handleError(error: unknown, showDialog: boolean = true): void {
    let message = "";
    let httpResponse = null;

    if (error instanceof HttpErrorResponse) {
      // Errors from HTTP Request
      const http = this.parseHttpError(error);
      message = http.message;
      httpResponse = http.httpResponse;
      // eslint-disable-next-line
    } else if ((error as any).innerError) {
      // Errors from breeze
      message = parseQueuedSaveFailedError(
        error as BreezeQueuedSaveFailedError,
      );
    } else {
      // Assuming these will be typical javascript errors
      // User most likely does not need to be notified
      // of these so we are not showing the dialog, but
      // will still pass the error on to the console as
      // normal

      // Uncomment these lines if you would like to see
      // console errors in the form of popups:
      // showDialog = !this.errorAlreadyOccurred(e);
      // message = e.message;

      this.logger.logException(error);

      this.zone.run(() =>
        // eslint-disable-next-line
        alert("Error: " + (error as any)?.message || "Undefined client error"),
      );

      // IMPORTANT: Rethrow the error otherwise it gets swallowed
      throw error;
    }

    if (showDialog) {
      toast("Error", message);
    }
  }

  private parseHttpError(response: HttpErrorResponse): {
    message: string;
    httpResponse?: string;
  } {
    if (
      this.serverSidePublicConfig.configData &&
      ["Local", "Development"].indexOf(
        this.serverSidePublicConfig.configData.environment ?? "",
      ) > -1 &&
      typeof response.error === "string" &&
      response.error.indexOf("<!DOCTYPE html>") > -1
    ) {
      const regex = new RegExp(/<div class="titleerror">(.*?)<\/div>/);
      const match = response.error.match(regex);

      return {
        message: match?.[1] ?? "An Exception has occurred",
        httpResponse: response.error,
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return { message: response.error };
  }
}

import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { EntityManagerProviderService } from "../../services//entity-manager-provider/entity-manager-provider.service";
import { ServerSideConfigDataService } from "../../services/server-side-config-data/server-side-config-data.service";
import { UnitOfWorkService } from "../../services/unit-of-work/unit-of-work.service";
import { RequireAuthenticatedUserGuard } from "../auth/require-authenticated-user.guard";

@Injectable({
  providedIn: "root",
})
export class BreezeSetupGuard implements CanActivate {
  constructor(
    private serverSide: ServerSideConfigDataService,
    private entityManagerProvider: EntityManagerProviderService,
    private uowService: UnitOfWorkService,
    private authGuard: RequireAuthenticatedUserGuard,
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    const isAuth = await this.authGuard.canActivate(next, state);

    if (!isAuth) {
      return Promise.resolve(false);
    }

    return this.serverSide
      .loadServerSideData()
      .then(() => this.entityManagerProvider.init())
      .then(() => {
        this.uowService.init();
        return true;
      });
  }
}

import { Routes } from "@angular/router";
import { AppGuard } from "./guards/app-guard/app.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/engagement",
    pathMatch: "full",
  },
  {
    path: "",
    canActivate: [AppGuard],
    children: [
      {
        path: "engagement",
        loadChildren: () => import("./engagement/routes"),
      },
      {
        path: "admin",
        loadChildren: () => import("./admin/routes"),
      },
      {
        path: "design-system",
        loadChildren: () => import("src/standard/pages/design-system/routes"),
      },
      {
        path: "development",
        loadChildren: () => import("src/standard/pages/development/routes"),
      },
      {
        path: "",
        loadChildren: () => import("src/standard/pages/error-pages/routes"),
      },
    ],
  },
];

export default routes;

export const appTitle = "BDO AIMM";
export const companyName = "BDO USA, P.C.";
export const companyDesc = "a Virginia Professional Service Corporation"
export const logoWidth = 1181.5;
export const logoHeight = 454.1;
export const helpUrl = "https://bdodigital.com/todo";

export const possibleLocales = ["en-US", "en-IN", "en-GB", "en-AU"];

export const baseSecurableName = "bdo.taxcredit.web";

export const DEFAULT_DECIMAL_PLACES = 5;

const secondsPerMinute = 60;
export const numIdleCountdownSeconds = secondsPerMinute * 2;
export const numIdleSeconds = secondsPerMinute * 15;

export const rowsPerPageOptions = [10, 25, 50, 100, 500];

const fromStorage = localStorage.getItem("defaultRowsPerPage") as
  | string
  | null
  | undefined;
export const defaultRowsPerPage = fromStorage ? Number(fromStorage) : 10;

export const FORMAT_EMPTY = "--";

// https://day.js.org/docs/en/display/format#list-of-localized-formats
export const FORMAT_DAYJS_APPEND_TIMEZONE: boolean = true;
export const FORMAT_DAYJS_API = "YYYY-MM-DDTHH:mm:ssZ";
export const FORMAT_DAYJS_DATE = "MM/DD/YYYY";
export const FORMAT_DAYJS_MONTH = "MMMM YYYY";
export const FORMAT_DAYJS_MONTH_DAY = "MMM. d";
export const FORMAT_DAYJS_YEAR = "YYYY";
export const FORMAT_DAYJS_TIME = "hh:mm A";
export const FORMAT_DAYJS_DATETIME =
  FORMAT_DAYJS_DATE + " " + FORMAT_DAYJS_TIME;

export const FORMAT_TRUE = "Yes";
export const FORMAT_FALSE = "No";

export const PRIME_CALENDAR_HOUR_FORMAT: "12" | "24" = "12";

export const DURATION_MS_TOAST_SUCCESS = 3_000;
export const DURATION_MS_TOAST_ERROR = 6_000;
export const DURATION_MS_TOAST_BUSY_DELAY = 200;
export const DURATION_MS_TOAST_MIN = 1_000;

export const virtualDirRoot = window.location.origin + "/";
export const configApi = virtualDirRoot + "api/config";
export const filesApi = virtualDirRoot + "api/fileManagement";
export const serviceName = virtualDirRoot + "breeze";
export const storeExpirationMs = 1000 * 60 * 60 * 24 * 30; // 30 days
export const dayMs = 1000 * 60 * 60 * 24; // 1 day
export const throttleMs = 200;
export const skipFieldsForMessage = [
  "rights",
  "createdDate",
  "id",
  "createdByUserId",
  "modifiedDate",
  "modifiedByUserId",
  "relatedRecordId",
  "sortOrder",
];

export const stateKeys = {
  metadataHash: appTitle + "-V2-metadataHash",
  annotatedMetadataHash: appTitle + "-V2-annotatedMetadataHash",
  lookupsHash: appTitle + "-V2-lookupsHash",
  breezeMetadata: appTitle + "-V2-breezeMetadata",
  lookups: appTitle + "-V2-lookups",
  annotatedMetadata: appTitle + "-V2-annotatedMetadata",
  savedRoute: appTitle + "-V2-savedRoute",
};

export const hashes = {
  currentMetadataHash: "",
  currentAnnotatedMetadataHash: "",
  currentLookupsHash: "",
};

export const grossContributionMarginAppUrl = "https://gcm.bdo.com/";
export const agiloftAppUrl = "https://bdousa.agiloft.com/ui/do/system/measureFont;page=uT1324elqh0IwKs00t71USZNfew10004.al";

export const AUTOSAVE_INTERVAL_MS: number = 15 * 1000;
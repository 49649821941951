import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { StandardLogService } from "src/standard/logging/logging";
import invariant from "tiny-invariant";
import { getApiError } from "../ts/formUtils";
import { toast } from "../ts/toast";
import {
  SecurityService,
  getSecurableName,
  userSummary$,
} from "./security.service";

export function getResolvedUrl(route: ActivatedRouteSnapshot): string {
  return route.pathFromRoot
    .map((v) => v.url.map((segment) => segment.toString()).join("/"))
    .join("/");
}

export const SecurityActivateGuard = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  logger: StandardLogService,
  security: SecurityService,
  router: Router,
) => {
  // const resolvedUrl = getResolvedUrl(next);
  const pageSecurableName = getSecurableName(state.url);
  invariant(pageSecurableName);

  try {
    const [summary] = await Promise.all([
      security.hydrateUserSummary(),
      security.hydrateCachedSecurableData(),
    ]);
    if (!summary) {
      return router.parseUrl(`/access-denied?loggedIn=false`);
    }

    //Set the account id for app insights logging
    logger.ensureUserIdIsSet(summary.employeeId);

    if (!summary.canBeLoggedIn) {
      return router.parseUrl(`/access-denied?canBeLoggedIn=false`);
    }

    const canRead = security.isActionAllowedSync(pageSecurableName, "Read");
    if (!canRead) {
      return router.parseUrl(`/access-denied?page=${pageSecurableName}`);
    }

    return true;
  } catch (error: unknown) {
    userSummary$.next([true, null]);
    logger.logException(error);
    const message = getApiError(error);
    toast("Error", message ?? "Unable to hydrate securable data");
    if (pageSecurableName !== getSecurableName("maintenance")) {
      return router.parseUrl("/maintenance");
    }

    return false;
  }
};

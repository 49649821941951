import { NgIf, NgTemplateOutlet } from "@angular/common";
import {
  AfterViewInit,
  Component,
  Input,
  ContentChild,
  TemplateRef,
  Type,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import invariant from "tiny-invariant";
import { TransitionState } from "../ts/formUtils";

export type GetContentFn<TContent> = () => Promise<
  Type<TContent> | { default: Type<TContent> }
>;

@Component({
  standalone: true,
  selector: "standard-lazy-component",
  template: `
    <ng-container *ngIf="transitionState !== 'idle'">
      <ng-container *ngTemplateOutlet="skeletonTemplate" />
    </ng-container>
    <ng-template #lazyContent />
  `,
  imports: [NgIf, NgTemplateOutlet],
})
export class StandardLazyComponent<
  TContent,
  TLazyProps extends Record<string, unknown>,
> implements AfterViewInit
{
  @Input() getContent!: GetContentFn<TContent>;

  _lazyProps: TLazyProps | undefined;
  @Input() set lazyProps(newVal: TLazyProps | undefined) {
    if (this._instance) {
      this.propagateLazyProps(this._lazyProps, newVal);
    }

    this._lazyProps = newVal;
  }
  get lazyProps() {
    return this._lazyProps;
  }

  @ContentChild("skeleton", { descendants: false })
  skeletonTemplate: TemplateRef<unknown> | null = null;

  @ViewChild("lazyContent", { read: ViewContainerRef })
  lazyContent!: ViewContainerRef;

  protected transitionState: TransitionState = "loading";
  private _instance: TContent | undefined;

  async ngAfterViewInit() {
    invariant(this.getContent);
    await this.lazyLoadContent();
  }

  async lazyLoadContent() {
    this.transitionState = "loading";

    const payload = await this.getContent();
    const ContentComponent = "default" in payload ? payload.default : payload;

    this.lazyContent.clear();
    const componentRef = this.lazyContent.createComponent(ContentComponent);
    this._instance = componentRef.instance;

    this.propagateLazyProps(undefined, this.lazyProps);

    this.transitionState = "idle";
  }

  propagateLazyProps(
    prev: TLazyProps | undefined,
    newProps: TLazyProps | undefined,
  ) {
    if (this.transitionState === "idle") {
      return;
    }

    if (!this._instance) {
      return;
    }

    const prevKeys = Object.keys(prev ?? {});
    const newKeys = Object.keys(newProps ?? {});
    const uniqueKeys = prevKeys
      .filter((t) => !newKeys.includes(t))
      .concat(newKeys);

    // eslint-disable-next-line
    const instance = this._instance as any;

    for (const key of uniqueKeys) {
      const prevVal = prev?.[key];
      const newVal = newProps?.[key];

      // Set to new value if it's set
      if (newVal !== undefined) {
        // eslint-disable-next-line
        if (instance[key] !== newVal) {
          // eslint-disable-next-line
          instance[key] = newVal;
        }
      }
      // Otherwise, clear out field value
      else if (prevVal !== undefined) {
        // eslint-disable-next-line
        instance[key] = undefined;
      }
    }
  }
}

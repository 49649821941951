<standard-skip-nav-link />

<div
  *ngIf="transitionState === 'idle' && widthCategory !== undefined"
  class="flex-grow-1 flex-shrink-1 overflow-hidden d-flex flex-column position-relative"
>
  <div class="overflow-hidden d-flex flex-grow-1">
    <div
      *ngIf="hasNavSide"
      [attr.data-bs-theme]="sideColorMode"
      class="flex-grow-0 flex-shrink-0 bg-body text-body border-end"
      [@navSideCollapserContent]="{
        value: navSideOverlayed
          ? 'void'
          : navSideExpanded
          ? 'expanded'
          : 'collapsed'
      }"
      style="width: var(--bs-w-sidenav)"
    >
      <ng-container *ngTemplateOutlet="navSideContent" />
    </div>

    <div class="d-flex flex-column flex-grow-1">
      <div [attr.data-bs-theme]="topColorMode" [class]="topStyleClass ?? ''">
        <standard-nav-top>
          <ng-container
            *ngTemplateOutlet="
              navTopTemplate;
              context: {
                registerNavTemplates: registerNavTemplates.bind(this)
              }
            "
          />

          <ng-template *ngIf="navTopLogoTemplate || hasNavSide" #logo>
            <standard-nav-side-button-collapser
              *ngIf="hasNavSide"
              class="border-end"
              style="margin-right: var(--bs-navbar-brand-margin-end)"
              [expanded]="navSideExpanded"
              (onToggle)="toggleNavSide($event)"
            />
            <div
              class="d-flex align-items-center text-body text-decoration-none"
              style="margin-right: 32px"
            >
              <ng-container *ngTemplateOutlet="navTopLogoTemplate" />
              <span class="fw-bold fs-3" style="line-height: 24px"
                >{{ appTitle }}</span
              >
            </div>
          </ng-template>

          <ng-template
            *ngIf="
              widthCategory !== undefined &&
              widthCategory > topStartToSideStartMaxSize &&
              navTopStartActionsTemplate
            "
            #startActions
          >
            <ng-container
              *ngTemplateOutlet="
                navTopStartActionsTemplate;
                context: { variant: 'top-nav' }
              "
            />
          </ng-template>

          <ng-template *ngIf="navTopMiddleLogoTemplate" #middleLogo>
            <ng-container *ngTemplateOutlet="navTopMiddleLogoTemplate" />
          </ng-template>

          <ng-template
            *ngIf="
              widthCategory !== undefined &&
              widthCategory > topEndToSideEndMaxSize &&
              navTopEndActionsTemplate
            "
            #endActions
          >
            <ng-container
              *ngTemplateOutlet="
                navTopEndActionsTemplate;
                context: { variant: 'top-nav' }
              "
            />
          </ng-template>
        </standard-nav-top>
      </div>

      <div
        #contentScroller
        class="d-flex flex-column flex-grow-1 overflow-y-auto"
      >
        <main class="flex-grow-1">
          <standard-skip-nav-content />
          <ng-content />
        </main>

        <div *ngIf="navBottomTemplate" class="pt-4">
          <ng-container *ngTemplateOutlet="navBottomTemplate" />
        </div>
      </div>
    </div>
  </div>

  <div
    [@navSideOverlayBg]="{
      value: !navSideOverlayed
        ? 'void'
        : navSideExpanded
        ? 'expanded'
        : 'collapsed'
    }"
    class="side-overlay position-fixed top-0 h-100 w-100 overflow-hidden bg-dark bg-opacity-50"
    (click)="toggleNavSide(false)"
  ></div>
  <div
    [attr.data-bs-theme]="sideColorMode"
    [@navSideOverlayContent]="{
      value: !navSideOverlayed
        ? 'void'
        : navSideExpanded
        ? widthCategory <= sideOverlayExpandedFullMaxSize
          ? 'expandedFull'
          : 'expanded'
        : 'collapsed'
    }"
    class="side-overlay position-fixed top-0 vh-100 bg-body text-body shadow"
    style="width: var(--bs-w-sidenav)"
  >
    <ng-container *ngTemplateOutlet="navSideContent" />
  </div>
</div>

<ng-template #navSideContent>
  <standard-nav-side
    class="h-100"
    headerStyleClass="px-3 border-bottom h-header"
    startActionsStyleClass="py-3 px-3"
    endActionsStyleClass="py-3 px-3"
  >
    <ng-template *ngIf="navSideHeaderTemplate || navSideOverlayed" #header>
      <ng-container *ngIf="navSideOverlayed">
        <div
          class="row flex-nowrap flex-grow-1 align-items-center"
          style="max-width: calc(100% - 20px)"
        >
          <div *ngIf="navSideHeaderTemplate" class="col-auto pe-0">
            <ng-container *ngTemplateOutlet="navSideHeaderTemplate" />
          </div>
          <div class="col-auto ms-auto">
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              (click)="toggleNavSide(false)"
            ></button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!navSideOverlayed">
        <ng-container *ngTemplateOutlet="navSideHeaderTemplate" />
      </ng-container>
    </ng-template>

    <ng-template
      *ngIf="
        navSideStartActionsTemplate ||
        (widthCategory !== undefined &&
          widthCategory <= topStartToSideStartMaxSize &&
          navTopStartActionsTemplate) ||
        (widthCategory !== undefined &&
          widthCategory > MD &&
          navSideStartLargishOnlyActionsTemplate)
      "
      #startActions
    >
      <ng-container
        *ngIf="
          widthCategory !== undefined &&
          widthCategory > MD &&
          navSideStartLargishOnlyActionsTemplate
        "
      >
        <ng-container
          *ngTemplateOutlet="
            navSideStartLargishOnlyActionsTemplate;
            context: { variant: 'side-nav' }
          "
        />
      </ng-container>
      <ng-container
        *ngIf="
          widthCategory !== undefined &&
          widthCategory <= topStartToSideStartMaxSize
        "
      >
        <ng-container
          *ngTemplateOutlet="
            navTopStartActionsTemplate;
            context: { variant: 'side-nav' }
          "
        />
      </ng-container>
      <ng-container
        *ngTemplateOutlet="
          navSideStartActionsTemplate;
          context: { variant: 'side-nav' }
        "
      />
    </ng-template>

    <ng-template
      *ngIf="
        navSideEndActionsTemplate ||
        (widthCategory !== undefined &&
          widthCategory <= topEndToSideEndMaxSize &&
          navTopEndActionsTemplate) ||
        (widthCategory !== undefined &&
          widthCategory <= MD &&
          navSideEndSmallishOnlyActionsTemplate)
      "
      #endActions
    >
      <ng-container
        *ngIf="
          widthCategory !== undefined &&
          widthCategory <= MD &&
          navSideEndSmallishOnlyActionsTemplate
        "
      >
        <ng-container
          *ngTemplateOutlet="
            navSideEndSmallishOnlyActionsTemplate;
            context: { variant: 'side-nav' }
          "
        />
      </ng-container>
      <ng-container
        *ngTemplateOutlet="
          navSideEndActionsTemplate;
          context: { variant: 'side-nav' }
        "
      />
      <ng-container
        *ngIf="
          widthCategory !== undefined && widthCategory <= topEndToSideEndMaxSize
        "
      >
        <ng-container
          *ngTemplateOutlet="
            navTopEndActionsTemplate;
            context: { variant: 'side-nav' }
          "
        />
      </ng-container>
    </ng-template>
  </standard-nav-side>
</ng-template>
